import { useEffect, useRef, useState, memo, lazy } from "react";
import html2pdf from 'html2pdf.js';
import _ from 'lodash';
import ExportIndex from "@components/organizerExport/exportIndex";
import '@components/organizerExport/organizer.css';
import usePageFramework from '@utilities/hooks/usePageFramework';
import { getFormBarcode } from '@utilities/constants/images';
import ErrorCodesPage from "./organizerRenderer/components/ErrorCodesPage";
import { formValuesChecker } from '@components/organizerExport/organizerRenderer/components/utils/exportFormSections';

//personalAndOther forms
const AboutYou = lazy(() => import('@views/personalAndOther/aboutYou'));
const AboutYourYear = lazy(() => import('@views/personalAndOther/aboutYourYear'));
const Dependents = lazy(() => import('@views/personalAndOther/dependents'));
const RefundPayment = lazy(() => import('@views/personalAndOther/refundPayments'));
const TaxPayments = lazy(() => import('@views/personalAndOther/taxPayments'));
const ForeignAssets = lazy(() => import('@views/personalAndOther/foreignAssets'));
//income forms
const Wages = lazy(() => import('@views/income/wages'));
const Income = lazy(() => import('@views/income/investmentIncome'));
const SocialSercurityRetirement = lazy(() => import('@views/income/socialSecurityRetirement'));
const BusinessIncome = lazy(() => import('@views/income/businessIncome'));
const RentalIncome = lazy(() => import('@views/income/rentalIncome'));
const FarmIncome = lazy(() => import('@views/income/farmIncome'));
const PassthroughIncome = lazy(() => import('@views/income/passthroughIncome'));
const MiscellaneousIncome = lazy(() => import('@views/income/miscellaneousIncome'));
const ForeignIncome = lazy(() => import('@views/income/foreignIncome'));
//deductions forms
const RetirementContributions = lazy(() => import('@views/deductions/retirementContributions'));
const MedicalExpenses = lazy(() => import('@views/deductions/medicalExpenses'));
const PropertyAndOtherStateTaxes = lazy(() => import('@views/deductions/propertyAndOtherStateTaxes'));
const MortgageInvestmentInterest = lazy(() => import('@views/deductions/mortgageInvestmentInterest'));
const CharitableContributions = lazy(() => import('@views/deductions/charitableContributions'));
const ChildCare = lazy(() => import('@views/deductions/childCare'));
const Tuition = lazy(() => import('@views/deductions/higherEducation'));
const VehicleEnergy = lazy(() => import('@views/deductions/vehicleEnergy'));
const HouseholdEmploymentTaxes = lazy(() => import('@views/deductions/householdEmploymentTaxes'));
const OtherDeductions = lazy(() => import('@views/deductions/otherDeductions'));

const Organizer = memo(({ isGenerating, setIsGenerating, activeOrganizer, addOrgExport }) => {
  const componentRef = useRef(null);
  const { dispatch, REDUX, selectState, ACTION, CARDSTATUS, downloadAllFiles } = usePageFramework();
  const dashboard = selectState(REDUX.DASHBOARD);
  const categories = dashboard?.length > 0 ? dashboard.filter((category) => category.title !== 'Opportunity Services') : [];
  const exportErrros = selectState(REDUX.SET_ORG_EXPORT_ERRORS);
  // eslint-disable-next-line
  const orgExportErrors = exportErrros || [];
  const [isHavingErrors, setIsHavingErrors] = useState(false);

  useEffect(() => {
    if (isGenerating) {
      (async () => {
        await printDocument();
      })();
    }
    // eslint-disable-next-line
  }, [isGenerating]);

  useEffect(() => {
    if (!(orgExportErrors.length === 0 || (orgExportErrors.length === 1 && !orgExportErrors[0]))) {
      setIsHavingErrors(true);
    }
    // eslint-disable-next-line
  }, [orgExportErrors]);

  async function printDocument() {
    console.log('generating...');

    const pages = Array.from(document.querySelectorAll('.pagebreak')); //getting all html elements that has page breaks here
    const opt = {
      margin: [16, 5, 13, 5],  // this prevents from barcodes and content frame overlapping a tiny bit
      filename: `${activeOrganizer.clientName}-${activeOrganizer.clientNumber}-${activeOrganizer.year}.pdf`,
      image: { type: 'jpeg' },
      html2canvas: { scale: 4.5, imageTimeout: 0 },
      jsPDF: { unit: 'mm', format: [216, 330], orientation: 'portrait', compress: true },
      pagebreak: { mode: ['css', 'legacy'] }
    };

    await organizerExportToPDF(pages, opt);
  }

  const organizerExportToPDF = async (pages, opt) => {
    // Function to handle wrapping long text
    const wrapText = (text, maxWidth) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "13px Arial";
      const words = text.split(" ");
      let line = "";
      const lines = [];

      let wordBreak = "";
      const wordBreakLines = [];
      
      for (let n = 0; n < words.length; n++) {

        const word = context.measureText(words[n])
        
        //does break-word to wrap a word if a single word value is too long for the textarea
        if (words?.length === 1) {
          
          if (word?.width > maxWidth) {
            const characters = words[n].split('');

            for (let m = 0; m < characters.length; m++) {
              const characterLine = wordBreak + characters[m];
              const characterMetrics = context.measureText(characterLine);
              const characterLineWidth = characterMetrics.width;

              if (characterLineWidth > maxWidth) {
                wordBreakLines.push(characterLine);
                wordBreak = '';
              } else if (m === characters.length - 1) {
                wordBreakLines.push(characterLine);
              } else {
                wordBreak = characterLine;
              }
            }
          } else {
            wordBreakLines.push(words[n])
          }
          
        }

        const testLine = line + words[n] + " ";
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      if (words?.length > 1) {
        lines.push(line);
        return lines.join("\n");
      } else {
        return wordBreakLines.join("\n");
      }

    };

    const formsPages = [];
    const formsPDFArray = [];
    let formNameAtPage = '';

    let subSection = null;
    let farmEquipment = null;

    let travelRowContainer = null;
    let travelCollapse = null;
    let firstTravelRow = null;
    let firstTravelLineItemRow = null;

    let passthroughContainer = null;
    let passthroughCollapse = null;
    let firstPassthroughRow = null;
    let firstPassthroughLineItemRow = null;

    let foreignBankContainer = null;
    let foreignBankCollapse = null;
    let firstForeignBankRow = null;
    let firstForeignBankLineItemRow = null;

    let worker = html2pdf().set(opt).from(pages[0]);

    //convert from image to pdf below
    if (pages.length > 1) {
      // worker is now a jsPDF instance
      worker = worker.toPdf();

      // slice each form into individual PDF pages, one form can have more than 1 PDF page
      const slicedPages = pages.slice(1);
      for (let i = 0, n = slicedPages.length; i <= n; i++) {
        let page = slicedPages[i];
        if (page) {
          _.forEach(page.querySelectorAll('textarea'), ta => {
            const textAreaMaxWidth = ta.offsetWidth - 32

            //if textarea has a value and a name create a new div and copy over the value from the textarea to it, and remove the original textarea from the pdf page. This is because the original textarea doesn't wrap when converted to a pdf.
            if (ta.value.trim().length > 0 && ta.value !== undefined && ta.name) {
              let divDom = document.createElement('div');
              //add original textarea's value to new div in wrapped text
              divDom.innerText = wrapText(ta.value, textAreaMaxWidth)
              //styling for new div
              divDom.style.border = '2px solid #CBCBCB';
              divDom.style.borderRadius = '5px';
              divDom.style.padding = '0 10px';
              divDom.style.height = '100px';
              divDom.style.boxSizing = 'border-box';
              divDom.style.display = 'table-cell';
              divDom.style.padding = '1em';
              divDom.style.fontSize = '13px';
              divDom.style.textAlign = 'left';
              ta.parentElement.parentElement.style.border = 'none';
              ta.parentElement.parentElement.style.display = 'table';
              ta.parentElement.parentElement.style.height = '100%';
              ta.parentElement.parentElement.style.lineHeight = '17px';
              ta.parentElement.parentElement.append(divDom);
              //original ta is removed otherwise there is a gap where the original textarea is and there will be duplicate text if user clicks 'generate PDF' again
              ta.parentElement.remove();
            }
          });
          if (page.id === 'Travel and Workday History') {
            travelRowContainer = page.querySelector('.expandableFieldsContainer');
            travelCollapse = page.querySelector('.rmd-collapse');

            const denseLineItemRow = page.querySelector('.expandableFieldsContainer')
              .querySelectorAll('.denseLineItemRow');

            firstTravelRow = document.createElement("div");
            firstTravelRow.classList.add('rmd-grid', 'expandableFieldsContainer');
            firstTravelRow.appendChild(denseLineItemRow[0]);

            page.querySelector('.rmd-collapse')
              .replaceChild(firstTravelRow, page.querySelector('.expandableFieldsContainer'));

          } else if (page.id === 'Passthrough Information') {
            passthroughContainer = page.querySelector('.fieldsContainer');
            passthroughCollapse = page.querySelector('.rmd-collapse');

            const denseLineItemRow = page.querySelector('.fieldsContainer')
              .querySelectorAll('.lineItemRow');

            firstPassthroughRow = document.createElement("div");
            firstPassthroughRow.classList.add('rmd-grid', 'fieldsContainer');
            firstPassthroughRow.appendChild(denseLineItemRow[0]);

            page.querySelector('.rmd-collapse')
              .replaceChild(firstPassthroughRow, page.querySelector('.fieldsContainer'));

          } else if (page.id === 'Report of Foreign Bank and Financial Accounts') {
            foreignBankContainer = page.querySelector('.expandableFieldsContainer');
            foreignBankCollapse = page.querySelector('.rmd-collapse');

            const denseLineItemRows = page.querySelector('.expandableFieldsContainer')
              .querySelectorAll('.denseLineItemRow');

            firstForeignBankRow = document.createElement("div");
            firstForeignBankRow.classList.add('rmd-grid', 'expandableFieldsContainer');
            firstForeignBankRow.appendChild(denseLineItemRows[0]);

            page.querySelector('.rmd-collapse')
              .replaceChild(firstForeignBankRow, page.querySelector('.expandableFieldsContainer'));

          } else if (page.id === "Farm Expenses") {
            subSection = page.querySelector("#subSection");
            farmEquipment = page.querySelector("#farmEquipment");
            const newDiv = document.createElement("div");
            subSection?.replaceChild(newDiv, farmEquipment);
          }

          worker = worker
            .from(page)
            .get('pdf')
            .then(pdf => {
              pdf.addPage();
              let pageCounter = pdf.internal.getNumberOfPages();
              let formName = page.getElementsByTagName('p')[0].innerHTML;
              // set up the array with all that is needed upfront before adding to PDF pages
              formsPages[i] = { formName: formName, pageCounter: pageCounter };
              console.log("debug console message: formsPages ", formsPages);
            })
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      }
    } else {
      // worker is now a jsPDF instance
      worker = worker.toPdf();
      const slicedPages = pages.slice(1);
      for (let i = 0, n = slicedPages.length; i <= n; i++) {
        let page = slicedPages[i];
        if (page) {
          worker = worker
            .from(page)
            .get('pdf')
            .then(pdf => {
              pdf.addPage();
            })
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      }
    }
    // do some post PDF page slicing processing and save the PDF file
    // footer and barcodes logic

    worker.get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();

      // for every PDF page add the bar code image
      let img = new Image();

      try {
        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          pdf.setPage(pageNum);
          pdf.setFontSize(10);
          pdf.setTextColor(100);
          // set the Page counter bottom right side on each page
          pdf.text('Page ' + String(pageNum) + ' of ' + String(totalPages), 210 - 10, 355 - 30, null, null, "right");

          if (pageNum === 1) {
            for (let i = 0; i <= (formsPages.length - 1); i++) {
              formsPDFArray[formsPages[i].pageCounter] = { formName: formsPages[i].formName };
            }
            // add the footer disclaimer for the index/summary page only
            pdf.text('Note: Unless noted otherwise, all data input by client. Prior year data shown in italics.', 15, 355 - 30, null, null, "left");
          }

          if (isHavingErrors && pageNum === 2) {
            pdf.text('Note: Error codes will appear under the input that has the error', 15, 355 - 30, null, null, 'left');
          }

          if (pageNum > 1) {
            // set the form name at the pageNum array location
            if ((formsPDFArray[pageNum] !== undefined)) {
              // read the form name from the array if entry exists at pageNum index
              // else use the last known read form name since form has multi pages
              formNameAtPage = formsPDFArray[pageNum].formName;
            }
            // set the barcode image for form at page pageNum
            img.src = (formNameAtPage !== 'energyEfficient') ? getFormBarcode(formNameAtPage) : '';
            // do not add a barcode if img source does not contain png file extension
            if (img.src.includes('png')) {
              pdf.addImage(img, 'PNG', 3, 3);
            }
            else {
              console.log("debug message: energyEfficient form has no bar code: img.src", img.src);
            }
          }
        }

      } catch (err) {
        console.error('error: ', err);
      }
    });

    if (addOrgExport) {
      const orgExportBlob = await worker.output('blob');
      const orgExportBlobUrl = URL.createObjectURL(orgExportBlob);
      const keepImageFormat = true;
      await downloadAllFiles(keepImageFormat, { orgExportBlobUrl: orgExportBlobUrl });

      setIsGenerating(false);
      console.log('done');
      dispatch(ACTION.setProgressText(''));
      dispatch(ACTION.setProgressVisible(false));
    } else {
      worker.save(opt.filename, { returnPromise: true }).then(() => {
        setIsGenerating(false);
        console.log('done');
        dispatch(ACTION.setProgressText(''));
        dispatch(ACTION.setProgressVisible(false));

        //prevents duplicates if user generates pdf again without refreshing
        subSection.appendChild(farmEquipment);
        //prevents only first rows from showing if user generates pdf twice or more without refreshing
        firstTravelLineItemRow = firstTravelRow.querySelector('#line-item-row-id-2-0');
        travelRowContainer.prepend(firstTravelLineItemRow);
        travelCollapse.replaceChild(travelRowContainer, firstTravelRow);
        //prevents only first rows from showing if user generates pdf twice or more without refreshing
        firstPassthroughLineItemRow = firstPassthroughRow.querySelector('#line-item-row-id-1-0');
        passthroughContainer.prepend(firstPassthroughLineItemRow);
        passthroughCollapse.replaceChild(passthroughContainer, firstPassthroughRow);
        //prevents only first rows from showing if user generates pdf twice or more without refreshing
        firstForeignBankLineItemRow = firstForeignBankRow.querySelector('#line-item-row-id-2-0');
        foreignBankContainer.prepend(firstForeignBankLineItemRow);
        foreignBankCollapse.replaceChild(foreignBankContainer, firstForeignBankRow);
      });
    }
  };

  //index logic
  let orgExportIndexArr = [];
  for (let section in categories) {
    for (let form in categories[section].cards) {
      orgExportIndexArr.push(categories[section].cards[form]);
    }
  }

  const showExportForm = (formName) => {
    return orgExportIndexArr.find(
      card => card.formName === formName && (card.statusId !== CARDSTATUS.NOT_STARTED || card.hasPriorData)
    );
  };

  const orgExportForms = [
    { formName: REDUX.ABOUT_YOU, component: <AboutYou isExportForm={true} /> },
    { formName: REDUX.DEPENDENTS, component: <Dependents isExportForm={true} /> },
    { formName: REDUX.ABOUT_YOUR_YEAR, component: <AboutYourYear isExportForm={true} /> },
    { formName: REDUX.REFUND_TAX_PAYMENT, component: <RefundPayment isExportForm={true} /> },
    { formName: REDUX.TAX_PAYMENTS, component: <TaxPayments isExportForm={true} /> },
    { formName: REDUX.FOREIGN_ASSETS, component: <ForeignAssets isExportForm={true} /> },
    { formName: REDUX.WAGES, component: <Wages isExportForm={true} /> },
    { formName: REDUX.INVESTMENT_INCOME, component: <Income isExportForm={true} /> },
    { formName: REDUX.RETIREMENT_INCOME, component: <SocialSercurityRetirement isExportForm={true} /> },
    { formName: REDUX.BUSINESS_INCOME, component: <BusinessIncome isExportForm={true} /> },
    { formName: REDUX.RENTAL_INCOME, component: <RentalIncome isExportForm={true} /> },
    { formName: REDUX.FARM_INCOME, component: <FarmIncome isExportForm={true} /> },
    { formName: REDUX.PASSTHRU_INCOME, component: <PassthroughIncome isExportForm={true} /> },
    { formName: REDUX.MISC_INCOME, component: <MiscellaneousIncome isExportForm={true} /> },
    { formName: REDUX.FOREIGN_INCOME, component: <ForeignIncome isExportForm={true} /> },
    { formName: REDUX.RETIREMENT_CONTRIBUTIONS, component: <RetirementContributions isExportForm={true} /> },
    { formName: REDUX.MEDICAL_EXPENSES, component: <MedicalExpenses isExportForm={true} /> },
    { formName: REDUX.PROPERTY_STATE_TAXES, component: <PropertyAndOtherStateTaxes isExportForm={true} /> },
    { formName: REDUX.MORTGAGE_INVESTMENT, component: <MortgageInvestmentInterest isExportForm={true} /> },
    { formName: REDUX.CONTRIBUTIONS, component: <CharitableContributions isExportForm={true} /> },
    { formName: REDUX.DEPENDENT_CARE, component: <ChildCare isExportForm={true} /> },
    { formName: REDUX.HIGHER_EDUCATION, component: <Tuition isExportForm={true} /> },
    { formName: REDUX.ENERGY_EFFICIENT, component: <VehicleEnergy isExportForm={true} /> },
    { formName: REDUX.HOUSEHOLD_EMPLOYMENT, component: <HouseholdEmploymentTaxes isExportForm={true} /> },
    { formName: REDUX.OTHER_DEDUCTIONS, component: <OtherDeductions isExportForm={true} /> }
  ];

  const pagebreak = (formName) => {
    const form = formValuesChecker.hasFormValues.find(item => item.formName === formName);
    return (
      formName === REDUX.BUSINESS_INCOME ||
      formName === REDUX.RENTAL_INCOME ||
      formName === REDUX.FARM_INCOME ||
      formName === REDUX.FOREIGN_INCOME ||
      formName === REDUX.FOREIGN_ASSETS ||
      formName === REDUX.PASSTHRU_INCOME || !form?.hasFieldValues ?
      '' : 'pagebreak'
    );
  };

  return (
    <>
      <div id="divToPrint" className="organizer" ref={componentRef}>
        <div className='pagebreak'>
          <ExportIndex index={orgExportIndexArr}></ExportIndex>
          {/* Commenting out Notes Export page for now until fully implemented */}
          {/* <NotesExport notes={orgExportNotesArr}></NotesExport> */}
        </div>
        {isHavingErrors && <ErrorCodesPage orgExportErrors={orgExportErrors} />}
        {orgExportForms.map(form =>
          showExportForm(form.formName) ?
            <div className={pagebreak(form.formName)} id={form.formName} key={form.formName}>
              <p hidden>{form.formName}</p>
              {form.component}
            </div> : null
        )}
      </div>
    </>
  );
});

export default Organizer;
