import { Button, Dialog } from 'react-md';

// custom modules
import '@components/dialog/emailInviteSuccessDialog/emailInviteSuccessDialog.css';
import { WARNING_ICON } from '@utilities/constants/images';

function EmailInviteSuccessDialog(props){
    const { buttonElement, className, buttonType,
        onClick, subtitleText, onCloseClick, onTryAgainClick,
        visible, cslStatus
    } = props;
    const isSaveSuccess = subtitleText.includes('successfully saved');

    const handleOnClick = () => {
        onClick && onClick();
    };

    const handleOnCloseClick = () => {
            onCloseClick && onCloseClick();
    };

    const handleOnTryAgainClick = () => {
        onTryAgainClick && onTryAgainClick();
    };

    return(
        <>
            { buttonElement && 
                <Button buttonType={buttonType} className={className} data-testid="test-button" onClick={handleOnClick} >
                {buttonElement}
            </Button>
            }
            <Dialog
                id="success-dialog"
                role="alertdialog"
                visible={visible}
                onRequestClose={()=> {}}
                aria-labelledby="dialog-title"
                className="WarningDialogContainer"
                data-testid="success-dialog"
            >
                <div className='WarningDialogInnerContainer' >
                    {isSaveSuccess ? <div className="SuccessDialogTitle">Congratulations!</div> : null}
                    {cslStatus === "failure" ? <div className="welcomeEmailFailureDialogTitle">
                    <img className="warningDialogIcon"
                    width="24"
                    height="20"
                    alt="Warning icon"
                    src={WARNING_ICON}
                    >
                    </img> Error</div> : null}
                    <div className="WarningDialogTextContainer">
                        <div className={cslStatus === "failure" ? "cslFailureDialogText" : "WarningDialogText11"}> {cslStatus === "failure" ? <span> 'The selected CSL's failed to activate.Please try again. If the problem persists please contact the support team.' </span> : subtitleText}</div>
                    </div>
                    <div className='successDialogButton'>
                        <Button 
                            className={`formNotesSaveActive ${cslStatus === "failure" ? `cslFailureCloseButton` : "failureButtonStyle" }`} 
                            id="dialog-close"  
                            data-testid="dialog-close" 
                            onClick={handleOnCloseClick} 
                        > 
                            {cslStatus === "failure" ? 'Close' : 'CLOSE'} 
                        </Button>
                        {cslStatus === "failure" && (
                            <Button className="formNotesSaveActive" id="dialog-try-again" data-testid="dialog-try-again" onClick={handleOnTryAgainClick}>
                                Try Again
                            </Button>
                        )}
                    </div>
                </div> 
            </Dialog> 
        </>
    );
}

export default EmailInviteSuccessDialog;