import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Card, DropdownMenu, MenuItem, Select, useToggle } from 'react-md';
import { TOP_LEFT_ANCHOR } from '@react-md/utils';
import { MoreVertSVGIcon } from '@react-md/material-icons';

import './headerImage.css';
import api from '@utilities/claApi';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import * as CARDSTATUS from '@utilities/constants/cardStatus';
import * as STRING from '@utilities/constants/strings';
import * as IMG from '@utilities/constants/images';
import { optionalMobileCards } from '@utilities/constants/mobileLimitedExperience';
import FormNotes from '@components/notes/formNotes';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import lastUserActivityDate from '@utilities/helpers/lastUserActivityDate';
import SubmitDialog from '@components/dialog/submitDialog';
import { deriveOrganizerActivity } from '@utilities/organizerData';
import { formValuesChecker } from '@components/organizerExport/organizerRenderer/components/utils/exportFormSections';
import * as EVENT from '@utilities/constants/triggerKeys';
import NotApplicableCard from './notApplicableCard';
import NotApplicableMenuItem from './notApplicableMenuItem';
import ChecklistCard from './checklist/checklistCard';
import ChecklistMenuItem from './checklist/checklistMenuItem';
import { isEsignaturePath, taxReturnStatusIsValid } from '@utilities/services/taxReturnService';
import useAdaptive from "@utilities/hooks/useAdaptive";
import { loadOrganizerDetails } from '@utilities/helpers/loadOrganizerDetails';
import SubmitToClaCard from './submitToClaCard';
import SubmitToClaMenuItem from './submitToClaMenuItem';
import { formFieldsByNames } from '@utilities/helpers/formFieldsByNames';
import getYear from '@utilities/helpers/getYear';

function HeaderImage() {
    const { history,
        selectState,
        REDUX,
        ACTION,
        NAV,
        dispatch,
        card,
        updateStatus,
        validateCurrentCard,
        setEntityFormState,
        uploadList,
        clearFormState,
        updateUploadList,
        location,
        downloadAllFiles,
    } = usePageFramework();
    const isAuthenticated = selectState(REDUX.IS_AUTHENTICATED);
    const formState = selectState(REDUX.ABOUT_YOUR_YEAR || null);
    const title = selectState(REDUX.TOOLBAR_TITLE);
    const isPractitioner = selectState(REDUX.IS_PRACTITIONER);
    const isAdmin = selectState(REDUX.IS_ADMIN);
    const isClient = selectState(REDUX.IS_CLIENT);
    const activeReturn = selectState(REDUX.ACTIVE_RETURN);
    const taxYear = selectState(REDUX.YEAR);
    const organizerId = selectState(REDUX.ORGANIZER_ID);
    const isFormLocked = selectState(REDUX.LOCK_FORMS);
    const priorYearDetails = selectState(REDUX.PRIOR_YEAR_DETAILS);
    const isFutureYear = selectState(REDUX.IS_FUTURE_YEAR);
    const dashboardState = selectState(REDUX.DASHBOARD) || [];
    const taxReturnStatus = selectState(REDUX.TAX_RETURN_STATUS);
    const organizer = selectState(REDUX.SELECTED_ORGANIZER);
    const taxpayerName = selectState(REDUX.TAXPAYER_NAME);
	const taxpayerSpouseName = selectState(REDUX.TAXPAYER_SPOUSE_NAME);
    const yearObject = getYear(selectState);
    const isDashboard = title === STRING.DASHBOARD || location?.pathname === '/dashboard' || location?.pathname === `/dashboard/${organizerId}`;
    const isMobileLimitedExperience = optionalMobileCards.includes(location?.pathname);
    const isDocManager = title === 'Document Manager';
    const isPDFexport = title === 'PDF Export';
    const isESignature = isEsignaturePath(location.pathname);

    const hasValidTaxReturnStatus = taxReturnStatusIsValid(taxReturnStatus);
    const [completeCardStyles, setCompleteCardStyles] = useState(null);
    const [notApplicableCardStyle, setNotApplicableCardStyle] = useState(null);
    const [notesVisible, setNotesVisible] = useState(false);
    const [visibleSubmitDialog, showSubmitDialog, hideSubmitDialog] = useToggle(false);
    const { isLaptopOrDesktop, isDesktopDevice } = useAdaptive();

    let [docYearOptions, setDocYearOptions] = useState([]);
    const displayName = activeReturn ? activeReturn.displayName : '';
    const taxYearReturnText = ` Tax Return`;

    const lastUserActivityOn = lastUserActivityDate(selectState);
    const isAboutYouCompleted = dashboardState[0]?.cards[0].formName === REDUX.ABOUT_YOU && dashboardState[0]?.cards[0].statusId === CARDSTATUS.COMPLETED;
    const isAboutYourYearCompleted = dashboardState[0]?.cards[2].formName === REDUX.ABOUT_YOUR_YEAR && dashboardState[0]?.cards[2].statusId === CARDSTATUS.COMPLETED;
    const isRefundPaymentPreferencesCompleted = dashboardState[0]?.cards[3].formName === REDUX.REFUND_TAX_PAYMENT && dashboardState[0]?.cards[3].statusId === CARDSTATUS.COMPLETED;
    const [isSubmittedToCLA, setIsSubmittedToCLA] = useState(false);

    const isPriorYear = taxYear < currentYear;

    // Check if Submit to CLA was done before showing the Summary PDF button to generate the PDF
    const isShowSummaryPDFbtn = isClient && isDashboard &&
        (isSubmittedToCLA || activeReturn?.formStatus === STRING.COMPLETED_TEXT);

    const renderNotApplicableButtons = card?.isRequired !== true && !isDashboard && !isDocManager;

    useEffect(() => {
        if (priorYearDetails?.priorYearLookup) {
            let docYearOptions = _.cloneDeep(priorYearDetails?.priorYearLookup);
            const filteredYears = docYearOptions.filter(year => year.value === currentYear || year.value === currentYear - 1);
            const sortedYears = _.sortBy(filteredYears, 'value');
            sortedYears.push({
                id: "",
                name: currentYear + 1,
                value: currentYear + 1
            });
            setDocYearOptions(sortedYears);
        }
    }, [priorYearDetails]);

    useEffect(() => {

        const cursorNotAllowedCardStyle = { opacity: 0.5, cursor: 'not-allowed' };

        const updateNotApplicableCardStyle = () => {
            if (isFormLocked || isFutureYear) {
                if (card?.statusId === CARDSTATUS.DOES_NOT_APPLY) {
                    setNotApplicableCardStyle({ border: '3px solid #EE5340', opacity: 0.5, cursor: 'not-allowed' });
                } else {
                    setNotApplicableCardStyle(cursorNotAllowedCardStyle);
                }
            } else {
                if (card?.statusId === CARDSTATUS.COMPLETED) {
                    setNotApplicableCardStyle(cursorNotAllowedCardStyle);
                } else if (card?.statusId === CARDSTATUS.DOES_NOT_APPLY) {
                    setNotApplicableCardStyle({ border: '3px solid #EE5340' });
                } else {
                    setNotApplicableCardStyle(null);
                }
            }
        };

        const updateCompleteCardStyles = () => {
            if (isFormLocked || isFutureYear) {
                if (card?.statusId === CARDSTATUS.COMPLETED) {
                    setCompleteCardStyles({ border: '3px solid #0BBA46', opacity: 0.5, cursor: 'not-allowed' });
                } else {
                    setCompleteCardStyles(cursorNotAllowedCardStyle);
                }
            } else {
                if (card?.statusId === CARDSTATUS.COMPLETED) {
                    setCompleteCardStyles({ border: '3px solid #0BBA46' });
                } else if (card?.statusId === CARDSTATUS.DOES_NOT_APPLY) {
                    setCompleteCardStyles(cursorNotAllowedCardStyle);
                } else {
                    setCompleteCardStyles(null);
                }
            }
        };

        if (hasValidTaxReturnStatus) {
            setCompleteCardStyles(cursorNotAllowedCardStyle);
        } else {
            updateNotApplicableCardStyle();
            updateCompleteCardStyles();
        }

    }, [card, isFormLocked, isFutureYear, hasValidTaxReturnStatus]);

    const showDialogAndGoBack = () => {
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle(''));
        dispatch(ACTION.setCustomDialogMsg("Great work!  We're bringing you back to the dashboard now."));
        dispatch(ACTION.setHideCustomDialogCloseButton(true));
        setTimeout(() => {
            dispatch(ACTION.setShowCustomDialog(false));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setCustomDialogMsg(''));
            dispatch(ACTION.setHideCustomDialogCloseButton(false));
            goBack();
        }, 3000);
    };

    const onSubmitError = () => {
        dispatch(ACTION.setProgressVisible(false));
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle('Error!'));
        dispatch(ACTION.setCustomDialogMsg('Unsuccessfully Sent! Please try again or contact CLA.'));
        setCompleteCardStyles(null);
    };
    const canSendWealthAdvisoryEmail = () => {
        let needWealthAdvisory = false;
        if (formState && formState.length > 0) {
            // Grab Section 5 for the Financial Services fields
            const financialSevices = formState?.find(x => x?.sectionId === 5);

            // Financial Services does not exist
            if (!financialSevices?.groups?.length) {
                return false;
            }

            const financialSelectFieldNames = [
                'aboutYourYearFinancialReviewSelect',
                'aboutYourYearFinancialValuedSelect',
                'aboutYourYearFinancialAssessSelect',
                'aboutYourYearFinancialUpdateSelect',
                'aboutYourYearFinancialBusinessSelect',
                'aboutYourYearFinancialIncometaxSelect'
            ];

            const hasYesValue = formFieldsByNames(financialSevices, financialSelectFieldNames)
                ?.filter((field) => field?.value === EVENT.KEY_YES);

            needWealthAdvisory = !!hasYesValue?.length;
        }
        return needWealthAdvisory;
    };

    const onSendClick = () => {
        hideSubmitDialog();
        if (canSendWealthAdvisoryEmail()) {
            //send email
            const notifData = { type: STRING.WEALTH_ADVISORY_INTEREST_EMAIL, data: { id: organizerId } };
            api.post('/notifications', notifData);
        }
        dispatch(ACTION.setProgressText('Sending to CLA...'));
        dispatch(ACTION.setProgressVisible(true));
        const json = {
            status: STRING.COMPLETED_TEXT,
            lastUserActivityOn,
        };
        const derivedOrganizerData = deriveOrganizerActivity(json, new Date());
        const payload = Object.assign({}, json, derivedOrganizerData);

        api.put(`/organizers/${organizerId}`, payload).then((response) => {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogTitle('Successfully sent to CLA!'));
            dispatch(ACTION.setCustomDialogMsg(<React.Fragment>We will get started on your return and reach out to you if we need any additional information. You can return at any time to upload documents if needed, but will not be able to add information to cards. Please take 2 minutes to provide feedback for our Exchange with <a className="successSurveyCls" href="https://survey.usabilla.com/live/s/674e221be3becb025129ab80">this survey.</a></React.Fragment>));
            setCompleteCardStyles({ border: '3px solid #0BBA46' });
            setIsSubmittedToCLA(true);
            dispatch(ACTION.setIsSubmittedToCLA(true));


            const clientInfo = {
                displayName: activeReturn?.displayName,
                clientNumber: activeReturn?.clientNumber ?? '',
                currentYear: activeReturn.currentYear,
                formStatus: STRING.COMPLETED_TEXT,
            };

            dispatch(ACTION.setActiveReturn(clientInfo));

        }).then(() => {
            // Trigger notification and rpa bot
            const notifData = { type: STRING.ORGANIZER_SUBMITTED, data: { id: organizerId } };
            return Promise.allSettled([
                api.post('/notifications', notifData),
                api.post('/triggers', notifData),
            ]);
        }).catch((error) => {
            console.error(error);
            onSubmitError();
        });

    };

    const goBack = () => {
        validateCurrentCard();
        dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
        dispatch(ACTION.setDashboardFilterStatus(-1));
        dispatch(ACTION.setDashboardSortRequirements('traditional'));
        history.push(`${NAV.DASHBOARD}/${organizerId}`);
    };

    const setCompletedOnClick = () => {
        if (card?.statusId === CARDSTATUS.COMPLETED) {
            updateStatus(CARDSTATUS.IN_PROGRESS);
            dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.IN_PROGRESS));
            setCompleteCardStyles(null);
            setNotApplicableCardStyle(null);
        } else {
            validateCurrentCard();
            if (card?.statusId !== CARDSTATUS.ERRORS) {
                updateStatus(CARDSTATUS.COMPLETED);
                dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.COMPLETED));
                setCompleteCardStyles({ border: '3px solid #0BBA46' });
                setNotApplicableCardStyle({ opacity: 0.5, cursor: 'not-allowed' });
                showDialogAndGoBack();
            } else {
                dispatch(ACTION.setShowCustomDialog(true));
                dispatch(ACTION.setCustomDialogTitle(''));
                dispatch(ACTION.setCustomDialogMsg('This form contains errors. Please correct before marking the form complete.'));
            }
        }
    };

    const setDoesNotApplyOnClick = () => {
        if (card?.statusDefault === CARDSTATUS.REQUIRED || card?.formName === REDUX.REFUND_TAX_PAYMENT) {
            return null;
        }

        if (card?.statusId === CARDSTATUS.DOES_NOT_APPLY) {
            updateStatus(CARDSTATUS.IN_PROGRESS);
            dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.IN_PROGRESS));
            setNotApplicableCardStyle(null);
            setCompleteCardStyles(null);
        } else {
            updateStatus(CARDSTATUS.DOES_NOT_APPLY);
            dispatch(ACTION.setCurrentCardStatus(CARDSTATUS.DOES_NOT_APPLY));
            setNotApplicableCardStyle({ border: '3px solid #EE5340' });
            setCompleteCardStyles({ opacity: 0.5, cursor: 'not-allowed' });
            showDialogAndGoBack();
        }
    };

    const showProgressDialog = () => {
        dispatch(ACTION.setProgressText(`Loading Client Data for\n${taxpayerName}${taxpayerSpouseName ? ` \nand ${taxpayerSpouseName}` : ''}`));
        dispatch(ACTION.setProgressVisible(true));
    };

    const hideProgressDialog = () => {
        dispatch(ACTION.setProgressText(''));
        dispatch(ACTION.setProgressVisible(false));
    };

    const onSelectChange = async (event, option, isDocManager) => {
        const selectedYear = option.value;

        if (!option.id) {
            // Handle future year
            dispatch(ACTION.setYear(selectedYear));
            dispatch(ACTION.setFutureYear(true));

            const futureOrganizer = priorYearDetails?.priorYearLookup ? _.find(priorYearDetails.priorYearLookup, { value: selectedYear }) : {};

            if (futureOrganizer?.id) {
                await updateUploadList(futureOrganizer.id).catch((err) => {
                    console.error('Failed to retrieve uploads', err);
                });
            } else {
                dispatch(ACTION.setUploadList([]));
            }

            if (isDocManager) {
                history.push(`${NAV.DOCUMENT_MANAGER}`);
            } else {
                history.push(`${NAV.DASHBOARD}/${organizerId}`);
            }

            return;
        }

        // For non-future year
        dispatch(ACTION.setYear(selectedYear)); // Ensure the selected year is set
        dispatch(ACTION.setFutureYear(false));
        showProgressDialog();
        clearFormState();

        try {
            // Refactored loadOrganizerDetails function
            const { organizer } = await loadOrganizerDetails(option.id, dispatch, ACTION, api, updateUploadList, setEntityFormState, activeReturn?.clientNumber, null, isClient, selectedYear);

            const { forms, taxpayer, spouse, year, entryExperience, id } = organizer;
            const taxPayerName = organizer?.taxpayer?.displayName;

            if (taxpayer) {
                dispatch(ACTION.setTaxpayerEmail(taxpayer.email || ''));
            }

            if (spouse) {
                dispatch(ACTION.setSpouseEmail(spouse.email || ''));
            }

            const limit = 50;
            const { data: { results: organizers } } = await api.get(`/organizers?filter=year eq ${currentYear}&limit=${limit}`);
            dispatch(ACTION.setClientOrganizers(organizers));
            const hasDifferentClients = new Set(organizers.map(organizer => organizer?.client?.id)).size > 1;
            dispatch(ACTION.setHasMultipleReturns(hasDifferentClients));
            dispatch(ACTION.setTaxpayerName(taxPayerName));

            let hasVehicleData = false;
            const formKeys = [];
            forms?.forEach((form) => {
                if (!formKeys.includes(form.key)) {
                    dispatch(ACTION.setForm(form.key, form.data));
                    hasVehicleData = hasVehicleData || form.key.startsWith(REDUX.VEHICLE);
                    formKeys.push(form.key);
                }
            });

            const priorData = await api.get(`/organizers/${option.id}/prior`).then((response) => {
                dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
                return response.data.data.taxData;
            }).catch((err) => {
                console.error(err);
            });

            if (priorData && priorData.priorYear && !hasVehicleData) {
                const vehicleFormData = loadVehicleData(priorData.priorYear, year);
                if (vehicleFormData && Object.keys(vehicleFormData).length) {
                    Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
                        setEntityFormState(vehicleKey, vehicleForm, organizerId);
                    });
                }
            }

            dispatch(ACTION.setCompletedEntryStep(entryExperience?.completedStep));
            if (year !== priorYearDetails.priorYear) {
                await updateUploadList(id).catch((err) => {
                    console.error('Failed to retrieve uploads', err);
                });
            } else {
                dispatch(ACTION.setUploadList([]));
            }

        } catch (err) {
            console.error('Error loading organizer details:', err);
        } finally {
            hideProgressDialog();

            if (isDocManager) {
                history.push(`${NAV.DOCUMENT_MANAGER}`);
            } else {
                history.push(`${NAV.DASHBOARD}/${organizerId}`);
            }
        }
    };



    const handleDownloadAll = async () => {
        if (!uploadList.length) {
            dispatch(ACTION.setProgressVisible(false));
            dispatch(ACTION.setCustomDialogTitle(''));
            dispatch(ACTION.setShowCustomDialog(true));
            dispatch(ACTION.setCustomDialogMsg('There are no downloadable files!'));
        } else {
            dispatch(ACTION.setProgressVisible(true));
            dispatch(ACTION.setUploadProgressVisible(true));
            dispatch(ACTION.setProgressText(STRING.DOWNLOADED_FILES_PROGRESS));

            downloadAllFiles(false, {}).then(() => {
                dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_COMPLETED));
            }).catch((err) => {
                dispatch(ACTION.setCustomDialogMsg(STRING.DOWNLOADED_FILES_ERRORED));
            }).finally(() => {
                dispatch(ACTION.setCustomDialogTitle(''));
                dispatch(ACTION.setProgressVisible(false));
                dispatch(ACTION.setShowCustomDialog(true));
                dispatch(ACTION.setUploadProgressVisible(true));
            });
        }
    };

    const handlePdfButtonOnClick = () => {
        clearFormState();
        history.push(`${NAV.ORGANIZER_EXPORT}`, {
            organizerId
        });
        formValuesChecker.val = [];
        window.scrollTo(0, 0);
        dispatch(ACTION.setProgressText('Loading Client Data...'));
        dispatch(ACTION.setProgressVisible(true));
        dispatch(ACTION.setCurrentCardKey(null));
    };

    const handleCompleteButtonOnClick = () => {
        if (isFormLocked || isFutureYear || hasValidTaxReturnStatus) {
            return;
        } else if (isDashboard) {
            return showSubmitDialog();
        } else if (card?.statusId !== CARDSTATUS.DOES_NOT_APPLY) {
            return setCompletedOnClick();
        }

        return;
    };

    const buildHeaderTitle = () => {
        if (isESignature) {
            return (
                <div className="clientInfoHeaderContainer" >
                    <div className='dashboardTitle'>
                        <p id="headerInfo">
                            eSignature
                        </p>
                    </div>
                </div>
            );
        }
        //TODO: Check for practitioner's view, what need to show?
        return (isDashboard && isAuthenticated ?
            <div className="clientInfoHeaderContainer" >
                <div className='dashboardTitle'>
                    <p id="headerInfo">
                        {organizer?.client?.displayName || STRING.EXCHANGE_1040}
                    </p>
                </div>
                <div className='dashboardTitle'>
                    <p id="clientId">
                        {
                            priorYearDetails?.priorYearLookup && isDesktopDevice ?
                                <Select
                                    options={docYearOptions}
                                    value={taxYear.toString()}
                                    id={'select-scroll-tab'}
                                    data-testid='select-scroll-tab'
                                    labelKey={'name'}
                                    valueKey={'value'}
                                    onChange={(event, option) => onSelectChange(event, option)}
                                    style={{ backgroundColor: '#ffffff', height: '30px', width: '72px', marginRight: '9px' }}
                                    listboxStyle={{ width: "auto" }}
                                />
                                : currentYear}
                        {isDesktopDevice && ((isPractitioner || isAdmin) && activeReturn ? `${taxYearReturnText} - ID: ${activeReturn.clientNumber}` : taxYearReturnText)}
                    </p>
                </div>
            </div>
            : <div className="clientInfoHeaderContainer">
                <div className="dashboardTitle">
                    <p id="headerInfo">
                        {isAuthenticated ? title : STRING.EXCHANGE_1040}
                    </p>
                </div>
                <div className='dashboardTitle'>
                    {title === STRING.EXCHANGE_MANAGER_1040 || !isAuthenticated ? null :
                        <div className='dashboardTitle'>
                            <p id="clientId">
                                {isDocManager &&
                                    <Select
                                        options={docYearOptions}
                                        value={taxYear.toString()}
                                        id={'select-scroll-tab'}
                                        data-testid='select-scroll-tab'
                                        labelKey={'name'}
                                        valueKey={'value'}
                                        onChange={(event, option) => onSelectChange(event, option, true)}
                                        style={{ backgroundColor: '#ffffff', height: '30px', width: '72px', marginRight: '9px' }}
                                        listboxStyle={{ width: "auto" }}
                                    />}
                                {(isPractitioner || isAdmin) && activeReturn && !isPDFexport ? `${taxYearReturnText} - ID: ${activeReturn.clientNumber}` : taxYearReturnText}
                            </p>
                        </div>
                    }
                </div>
            </div>
        );
    };

    const getMenuItems = () => {
        const checklistMenuItem = <ChecklistMenuItem isDashboard={isDashboard} organizerId={organizerId} isFutureYear={isFutureYear} />;
        if (isFormLocked) {
            return [
                checklistMenuItem,
                <MenuItem key='header-notes-option' className='cardMenuItems'
                    onClick={() => { setNotesVisible(!notesVisible); }} > {'Notes'} </MenuItem>
            ];
        } else {
            return [
                checklistMenuItem,
                <NotApplicableMenuItem
                    renderCard={renderNotApplicableButtons}
                    cardClass={`cardMenuItems ${card?.statusId === CARDSTATUS.DOES_NOT_APPLY ? 'selected' : ''}`}
                    cardStyle={card?.statusId !== CARDSTATUS.COMPLETED ? {} : { cursor: 'not-allowed' }}
                    isOnClickEnabled={card?.statusId !== CARDSTATUS.COMPLETED}
                    onCardClick={setDoesNotApplyOnClick}
                />,
                <MenuItem
                    key='header-notes-option'
                    className='cardMenuItems'
                    onClick={() => {
                        setNotesVisible(!notesVisible);
                    }}
                > {'Notes'}
                </MenuItem>,
                <SubmitToClaMenuItem
                    hasValidTaxReturnStatus={hasValidTaxReturnStatus}
                    isDashboard={isDashboard}
                    statusId={card?.statusId}
                    setCompletedOnClick={setCompletedOnClick}
                    showSubmitDialog={showSubmitDialog} />,
                isShowSummaryPDFbtn && <MenuItem
                    key='header-summarypdf-option'
                    className={!isDashboard && card?.statusId === CARDSTATUS.COMPLETED ? 'cardMenuItems selected' : 'cardMenuItems'}
                    onClick={() => handlePdfButtonOnClick()}
                    data-testid='header-summarypdf-option'
                > {STRING.SUMMARY_PDF}
                </MenuItem>
            ];
        }
    };

    const renderSubmitDialog = () => {
        return <SubmitDialog
            onConfirmSubmit={onSendClick}
            visible={visibleSubmitDialog}
            onCancelSubmit={hideSubmitDialog}
            isAboutYouCompleted={isAboutYouCompleted}
            isAboutYourYearCompleted={isAboutYourYearCompleted}
            refundPaymentPreferences={{ 'isCompleted': isRefundPaymentPreferencesCompleted, 'isRequired': dashboardState[0]?.cards[3].isRequired }}
        />;
    };

    const renderHeaderCards = () => {
        const shouldShowCards = isAuthenticated && activeReturn
            && title !== STRING.EXCHANGE_MANAGER_1040;
        const shouldShowMenu = isLaptopOrDesktop && isAuthenticated && activeReturn
            && title !== STRING.EXCHANGE_MANAGER_1040 && !isDocManager;

        return (
            <div className="dashboardHeaderCardsContainer">
                {shouldShowCards && <div className="headerImgCardsContainer" style={{ display: 'flex' }}>
                    <ChecklistCard isDashboard={isDashboard} organizerId={organizerId} isFutureYear={isFutureYear} />
                    <NotApplicableCard
                        renderCard={renderNotApplicableButtons}
                        cardStyle={card && notApplicableCardStyle}
                        isOnClickEnabled={!(isFormLocked || (card?.statusId === CARDSTATUS.COMPLETED))}
                        icon={card?.statusId === CARDSTATUS.DOES_NOT_APPLY ? IMG.NA_ICON_ACTIVE : IMG.NA_ICON_NORMAL}
                        onCardClick={setDoesNotApplyOnClick}
                    />
                    {
                        isShowSummaryPDFbtn &&
                        <Card className="dashboardHeaderIconCard entryNoShadow dashboardHeaderCard summaryPDFCard"
                            style={card && notApplicableCardStyle}
                            onClick={() => handlePdfButtonOnClick()}
                        >
                            <div
                                id='summaryPDFCard'
                                className={card?.statusDefault === CARDSTATUS.REQUIRED ? 'dashboardHeaderIconName headerCardsPadding disableCursor' : 'dashboardHeaderIconName headerCardsPadding'}
                            >
                                <div className="dashboardHeaderIconName">
                                    <img
                                        alt='Org Export PDF Download Icon'
                                        src={IMG.ORG_EXPORT_DOWNLOAD}
                                        width={34}
                                        height={28}
                                    />
                                    <p style={{ textAlign: 'center' }}>{STRING.SUMMARY_PDF}</p>
                                </div>
                            </div>
                        </Card>
                    }
                    {isDocManager && (
                        <Card
                            className="dashboardHeaderIconCard entryNoShadow dashboardHeaderCard"
                            onClick={!isPriorYear && handleDownloadAll} // Disable onClick if isPriorYear is true
                            style={{
                                pointerEvents: isPriorYear ? 'none' : 'auto',
                                opacity: isPriorYear ? 0.5 : 1,
                            }}
                        >
                            <div className='dashboardHeaderIconName headerCardsPadding'>
                                <div className="fileUploadCard">
                                    <img
                                        alt='download all'
                                        src={IMG.DOWNLOAD_ALL_ICON}
                                        style={{ opacity: isPriorYear ? 0.5 : 1 }} // Dim the icon when disabled
                                    />
                                    <p style={{ textAlign: 'center', opacity: isPriorYear ? 0.5 : 1 }}>
                                        Download All
                                    </p>
                                </div>
                            </div>
                        </Card>
                    )}

                    {(isDashboard || card) && <FormNotes
                        setNotesVisible={setNotesVisible}
                        notesVisible={notesVisible}
                    />}
                    <SubmitToClaCard
                        isDocManager={isDocManager}
                        isDashboard={isDashboard}
                        formStatus={activeReturn?.formStatus}
                        completeCardStyles={completeCardStyles}
                        statusId={card?.statusId}
                        onCardClick={handleCompleteButtonOnClick} />
                </div>}
                {shouldShowMenu && <div className="headerCardMoreBtnContainer">
                    <DropdownMenu
                        id="headerImgEllipsis"
                        buttonType="icon"
                        aria-label="Options..."
                        anchor={TOP_LEFT_ANCHOR}
                        // onVisibilityChange={onVisibilityChange}
                        disabled={isFutureYear}
                        buttonChildren={<MoreVertSVGIcon className="dropDownMenu moreIconBtn" />}
                        data-testid="header-option-menu"
                    >
                        {getMenuItems()}
                    </DropdownMenu>
                </div>}
            </div>
        );
    };

    return (
        <>
            <div className="imgContainer">
                {buildHeaderTitle()}
                {renderSubmitDialog()}
                {!isPDFexport && isLaptopOrDesktop && renderHeaderCards()}
            </div>
            {!isLaptopOrDesktop && (!isMobileLimitedExperience || isDesktopDevice) && renderHeaderCards()}
        </>
    );
}

export default HeaderImage;