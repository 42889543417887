import React, { useEffect, useState } from 'react';
import { useToggle } from '@react-md/utils';
import { Dialog, DialogHeader, DialogContent, DialogFooter } from "@react-md/dialog";
import { Button, Grid, GridCell, Divider } from 'react-md';
import moment from 'moment-timezone';
import axios from 'axios';

import {
    CANCEL,
    CLOSE,
    UPDATE,
    MANAGE_NOTIFICATIONS_UPDATE_SUCCESS_HEADER,
    MANAGE_NOTIFICATIONS_UPDATE_FAILED_HEADER,
    MANAGE_NOTIFICATIONS_UPDATE_SUCCESS_TEXT,
    MANAGE_NOTIFICATIONS_UPDATE_FAILED_TEXT,
} from '@utilities/constants/strings';
import * as IMG from '@utilities/constants/images.js';
import { getAccount } from '@utilities/authentication';

import { getClientList, updateNotificationSuppression } from '@utilities/services/manageNotificationsService';

import GridCellEmailTooltip from '@components/gridCellEmailTooltip';
import CustomOverlaySpinner from '@components/customOverlaySpinner';
import CustomSwitch from '@components/customSwitch';
import GridCellEmptyList from '@components/gridCellEmptyList';

import './ManageNotificationsDialog.css';


function ManageNotificationsDialog(props) {
    const { organizerId, disableManageNotificationsDialog } = props;
    const [userList, setUserList] = useState();
    const [resultText, setResultText] = useState('');
    const [resultHeader, setResultHeader] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const account = getAccount();

    const [showResultDialog, enableResultDialog, disableResultDialog] = useToggle(false);

    const HAS_SELECTED_USERS = userList?.filter(x => x.isSelected !== x.defaultSelected).length > 0;

    const handleResultDialogClose = async () => {
        disableResultDialog();
            
        if (resultText === MANAGE_NOTIFICATIONS_UPDATE_SUCCESS_TEXT) {
            disableManageNotificationsDialog();
        }
    }

    const getUserList = async () => {
        const userData = [];
        setIsLoading(true);
        await getClientList(organizerId)
            .then((users) =>
                userData.push(...(users?.map(user => ({
                    ...user,
                    name: user.displayName,
                    isSelected: !!user.suppressAllScheduledNotifications,
                    defaultSelected: !!user.suppressAllScheduledNotifications,
                    allSchedNotificationSuppressedAt: moment(user.updatedOn)
                        .tz('America/Chicago')
                        .format('MM/DD/YYYY - HH:mm[CST]'),
                }))))
            );
        setIsLoading(false);
        setUserList(userData);
    }

    const handleSendSuppressNotificationsClick = async () => {
        if (HAS_SELECTED_USERS) {
            const cancelToken = axios.CancelToken;
            const cancelSource = cancelToken.source();

            const validUsers = userList.filter(u => u.isSelected !== u.defaultSelected);
            const suppressed = validUsers.filter(u => u.isSelected).map(u => ({ _id: u.id, schedNotificationSuppressedBy: account.name }));
            const notSuppressed = validUsers.filter(u => !u.isSelected).map(u => ({ _id: u.id, schedNotificationSuppressedBy: account.name }));
            
            setIsLoading(true);
            
            let requests = [];

            if (suppressed?.length) {
                requests = requests.concat(updateNotificationSuppression(suppressed, true, cancelSource));
            }
            if (notSuppressed?.length) {
                requests = requests.concat(updateNotificationSuppression(notSuppressed, false, cancelSource));
            }

            const result = await Promise.all(requests)
                .then(responses => !!responses?.length)
                .catch(error => {
                    cancelSource.cancel(error.message);
                    return false;
                });

            setResultHeader(result ? MANAGE_NOTIFICATIONS_UPDATE_SUCCESS_HEADER : MANAGE_NOTIFICATIONS_UPDATE_FAILED_HEADER);
            setResultText(result ? MANAGE_NOTIFICATIONS_UPDATE_SUCCESS_TEXT : MANAGE_NOTIFICATIONS_UPDATE_FAILED_TEXT);

            setIsLoading(false);
            enableResultDialog();
        }
    }

    const handleRowCheckboxClick = (event) => {
        setUserList(prevData => {
            return prevData.map(x => {
                if (x.id === event.target.value) {
                    x.isSelected = event.target.checked;
                }
                return x;
            })
        })
    }

    const renderRowGridCell = (user, propertyName, index, colSpan, customClass) => {
        const isNotSuppressedTextStyle = propertyName === 'schedNotificationSuppressedBy' && !user.suppressAllScheduledNotifications ? 'not-suppressed' : '';
        const isNotSuppressedText = propertyName === 'schedNotificationSuppressedBy' && !user.suppressAllScheduledNotifications ? 'Not Suppressed' : null;
        return (
            <GridCell
                colSpan={colSpan}
                className={`manage-notifications-cell ${customClass} ${isNotSuppressedTextStyle}`}
                data-testid={`manage-notifications-${propertyName}-${index}`}
            >
                {
                    propertyName === 'schedNotificationSuppressedBy' && user.suppressAllScheduledNotifications
                        ? <div className="suppressedBy-text">
                            <span data-testid="manage-notification-suppressedBy-name">{<EmailOrText user={user} />}</span>
                            <span>{user.allSchedNotificationSuppressedAt}</span>
                        </div>
                        : isNotSuppressedText || user[propertyName]
                }
            </GridCell>
        )
    }

    const renderHeaderGridCell = (colSpan, cellText, customClass = '') => {
        return (
            <GridCell
                colSpan={colSpan}
                className={`manage-notifications-cell-header ${customClass}`}
            >
                {cellText}
            </GridCell>
        );
    }

    const EmailOrText = ({ user }) => <>
        {
            (/.+@.+/i).test(user.schedNotificationSuppressedBy)
                ? <GridCellEmailTooltip {...{ user, propertyName: 'schedNotificationSuppressedBy', colSpan: 3 }} />
                : user.schedNotificationSuppressedBy
        }
    </>;

    useEffect(() => {
        getUserList();
    }, [organizerId]);

    return (
        <>
            <Dialog
                id="result-dialog"
                role="alertdialog"
                visible={showResultDialog}
                onRequestClose={() => { }}
                className={'manage-notifications-result-dialog'}
                aria-labelledby="result-dialog"
                data-testid="result-dialog">
                <DialogHeader className={`manage-notifications-result-dialog-header`}>
                    {
                        resultHeader === MANAGE_NOTIFICATIONS_UPDATE_FAILED_HEADER
                            ? <>
                                <img
                                    className="warningDialogIcon"
                                    width="24"
                                    height="20"
                                    alt="Warning icon"
                                    src={IMG.WARNING_ICON}
                                /> {resultHeader}
                            </>
                            : resultHeader
                    }
                </DialogHeader>
                <DialogContent className={`manage-notifications-result-dialog-content`}>
                    {resultText}
                    <Button
                        id="dialog-closebtn"
                        className={`manage-notifications-send-button`}
                        style={{ marginTop: '30px' }}
                        onClick={handleResultDialogClose}
                    >
                        {CLOSE}
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog
                id="manage-notifications-dialog"
                role="alertdialog"
                visible={!showResultDialog}
                onRequestClose={() => { }}
                className={'manage-notifications-dialog'}
                aria-labelledby="manage-notifications-dialog-title"
                data-testid="manage-notifications-dialog-title">
                <DialogHeader className={'manage-notifications-dialog-header'}>
                    <div>
                        <p className={'manage-notifications-header-text'}>Manage Client Notifications</p>
                        <p className={'manage-notifications-sub-header-text'}>The following will suppress non-essential notifications to the client. Clients will still receive essential notifications regarding eSignature and welcome invitations.</p>
                    </div>
                </DialogHeader>
                <DialogContent className={'manage-notifications-dialog-body'}>
                    <Grid className='manage-notifications-header-container'>
                        {renderHeaderGridCell(2, 'Suppression')}
                        {renderHeaderGridCell(2, 'User', 'header-name-cell')}
                        {renderHeaderGridCell(3, 'Email Address')}
                        {renderHeaderGridCell(1, 'Role')}
                        {renderHeaderGridCell(4, 'Suppressed By', 'grid-suppressedBy-header')}
                    </Grid>
                    <div className="manage-notifications-grid-content">
                    <CustomOverlaySpinner isLoading={isLoading} />
                    {
                        userList && !userList.length && !isLoading && (
                            <Grid className='manage-notifications-grid-container' data-testid={`manage-notifications-grid-container`}>
                                <GridCellEmptyList />
                            </Grid>
                        )
                    }
                    {
                        userList
                            ? userList.map((user, index) => (
                                <Grid key={`user-row-${index}`} className='manage-notifications-grid-container' data-testid={`manage-notifications-grid-container`}>
                                    <GridCell colSpan={2}>
                                        <CustomSwitch
                                            data-testid={`manage-notifications-checkbox-${index}`}
                                            id={`manage-notifications-checkbox-${index}`}
                                            key={`manage-notifications-checkbox-${index}`}
                                            label={user.isSelected ? 'On' : 'Off'}
                                            onChange={handleRowCheckboxClick}
                                            value={user.id}
                                            disabled={isLoading}
                                            checked={user.isSelected}
                                        />
                                    </GridCell>
                                    {renderRowGridCell(user, 'name', index, 2, 'grid-name-cell')}
                                    {<GridCellEmailTooltip {...{ user, propertyName: 'email', index, colSpan: 3, className: 'manage-notifications-cell' }} />}
                                    {renderRowGridCell(user, 'role', index, 1, 'grid-role-cell')}
                                    {renderRowGridCell(user, 'schedNotificationSuppressedBy', index, 4, 'grid-suppressedBy-cell')}
                                    <GridCell colSpan={12} className={'manage-notifications-gridCell-padding'}>
                                        <Divider className={'manage-notifications-grid-divider'} />
                                    </GridCell>
                                </Grid>
                            ))
                            : (<div className="manage-notifications-loading-container" />)
                    }
                    </div>
                </DialogContent>
                <DialogFooter className={'manage-notifications-dialog-footer'}>
                    <div>
                        <Button
                            id="dialog-cancelbtn"
                            className={`manage-notifications-cancel-button-active`}
                            disabled={isLoading}
                            onClick={disableManageNotificationsDialog}
                        >
                            {CANCEL}
                        </Button>
                        <Button
                            id="dialog-sendbtn"
                            className={`manage-notifications-send-button`}
                            disabled={!HAS_SELECTED_USERS || isLoading}
                            onClick={() => handleSendSuppressNotificationsClick()}
                        >
                            {UPDATE}
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default ManageNotificationsDialog;